






























import { User } from "@/types";
import Vue from "vue";
export default Vue.extend<any, any, any, any>({
  name: "AppBar",
  components: {
    Account: () =>
      import(
        /* webpackChunkName: "account" */
        "./Account.vue"
      ),
    DrawerToggle: () =>
      import(
        /* webpackChunkName: "drawer-toggle" */
        "./DrawerToggle.vue"
      ),
    Notifications: () =>
      import(
        /* webpackChunkName: "notifications" */
        "./Notifications.vue"
      ),
    NewOrders: () =>
      import(
        /* webpackChunkName: "new-orders" */
        "./NewOrders.vue"
      ),
  },
  computed: {
    name() {
      return this.$route.name;
    },
    // ...sync('app', [
    //   'drawer',
    //   'mini',
    // ]),
    // name: get('route/name'),
    mini: {
      get() {
        return this.$store.getters.mini;
      },
      set() {
        this.setMini();
      },
    },
    drawer() {
      return this.$store.getters.drawer;
    },
    user(): User {
      return this.$store.getters.user;
    },
  },
  data: () => ({
    // name: "route/name",
  }),
  methods: {
    setMini() {
      this.$store.dispatch("toggleMini");
    },
    setDrawer() {
      this.$store.dispatch("setDrawer", !this.drawer);
    },
  },
});
